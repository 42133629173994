import {Center, Flex, Text} from "@chakra-ui/react";
import {useContractContext} from "../../context/Contract";

export default function WalletConnectError() {
    const {errMsg} = useContractContext();

    return (
        <>
            {
                errMsg &&
                <Center>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        w={"50%"}
                        h={"30px"}
                        bgColor={"rgba(196,30,30,0.73)"}
                    >
                        <Text>{errMsg}</Text>
                    </Flex>
                </Center>

            }
        </>
    );
};
