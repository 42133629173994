import React from "react";
import Twitter from "./assets/social-media-icons/twitter.webp";
import {Box, Flex, Image, Link} from "@chakra-ui/react";

import wildWarZLogo from "./assets/logo/wildwarz.png";

const twitterAccountLink = process.env.REACT_APP_TWITTER_LINK;

const NavBar = () => {
    return (
        <Flex
            justify={"space-between"}
            align={"center"}
            padding={"30px"}
        >
            <Flex
                justify={"space-around"}
                align={"center"}
                width={"40%"}
                padding={"30px"}
            >
                <Box
                    w={"130px"}
                    className={"logo"}
                >
                    <Image
                        src={wildWarZLogo}
                        alt="WildWarZ Logo"
                        align={"center"}
                        w={"80px"}
                    />
                </Box>

                {/*{isConnected ? (*/}
                {/*    <Box margin={"0 15px"}>Connected</Box>*/}
                {/*) : (*/}
                {/*    <Button*/}
                {/*        backgroundColor={"#D6517D"}*/}
                {/*        borderRadius={"5px"}*/}
                {/*        boxShadow={"0px 2px 2px 1px #0F0F0F"}*/}
                {/*        color={"white"}*/}
                {/*        cursor={"pointer"}*/}
                {/*        fontFamily={"inherit"}*/}
                {/*        padding={"15px"}*/}
                {/*        margin={"0 15px"}*/}
                {/*        onClick={connectAccount}*/}
                {/*    >*/}
                {/*        Connect*/}
                {/*    </Button>*/}
                {/*)}*/}
            </Flex>
            { /* Left Side - Social Media Icons */}
            <Flex justify={"space-around"} width={"40%"} padding={"0 75px"}>
                <Link href={twitterAccountLink} target={"_blank"}>
                    <Image src={Twitter} boxSize={"42px"} margin={"0 15px"}/>
                </Link>
            </Flex>

        </Flex>
    )
}

export default NavBar;
