import {useEffect, useState} from "react";
import {Box, Center, Flex, Spacer, Text} from "@chakra-ui/react";
import WalletConnectError from "./components/web3/WalletConnectError";
import {useWeb3React} from "@web3-react/core";
import {useContractContext} from "./context/Contract";
import {injected} from "./dapp/connectors";
import Marquee from "./components/Marquee";
import ConnectButton from "./components/buttons/ConnectButton";
import MintBox from "./components/MintBox";
// import Marquee from "react-fast-marquee";

const publicNetworkId = process.env.REACT_PUBLIC_NETWORK_ID || '137';

const MainMint = () => {
    const [mintAmount, setMintAmount] = useState(1);
    const [userMessage, setUserMessage] = useState("");

    const {
        connector,
        library,
        account,
        activate,
        deactivate,
        active,
        error,
        setError,
        chainId
    } = useWeb3React();
    const {errMsg, setErrMsg} = useContractContext();

    useEffect(() => {
        async function loadInjectedWallet() {
            const isAuthorized = await injected.isAuthorized();
            if (isAuthorized) {
                await activate(injected);
            }
        }

        if (typeof window.ethereum !== 'undefined') {
            try {
                loadInjectedWallet().then();
            } catch (error) {
                if (error instanceof Error) setError(error);
            }
        }
    }, [activate, setError]);

    useEffect(() => {
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ chainId", chainId);
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ active", active);
        // if (active) {
        console.log("chainId", chainId);
        if (
            chainId &&
            chainId.toString() !== publicNetworkId
        ) {
            setErrMsg(`Change the network to Polygon Mainnet.`);
        } else {
            setErrMsg('');
        }
        // } else {
        //     setErrMsg('');
        // }
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ errMsg", errMsg);

    }, [active, chainId, setErrMsg]);

    const DATA_LIST = [
        "HAPPY MINTING!",
        "HAPPY MINTING!",
        "HAPPY MINTING!",
        "HAPPY MINTING!",
        "HAPPY MINTING!",
    ];

    return (
        <Flex
            justify={"center"}
            flexDir={"column"}
            align={"center"}
            height={"100vh"}
            paddingBottom={"150px"}
        >
            <Box
                h={"76%"}
                // width={"520px"}
                w={{sm: "70%", base: "80%", md: "50%", lg: "50%"}}
                // bg={useColorModeValue("white", "gray.800")}
                bgImage="linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))"
                mt={-10}
                // position={"absolute"}
                // left={"-25em"}
                // left={null}
                // left={[null, null, null, "50px"]}
                shadow="lg"
                rounded="lg"
                overflow="hidden"
            >
                <div>
                    <Text
                        fontSize={"3.5vmax"}
                        textShadow={"0 0.4vw #000000"}
                        className={"gradient"}
                    >
                        WildWarZ Genesis Mint PASS
                    </Text>
                    <Text
                        fontSize={"3.2vmin"}
                        letterSpacing={"-5.5%"}
                        fontFamily={"Spartan"}
                        textShadow={"0 2px 2px #000000"}
                    >
                        Grab your Genesis Whitelist/Freemint NFT PASS.
                    </Text>
                </div>
                {
                    active && account ? (
                        <Center>
                            <Flex
                                justify={"center"}
                                align={"center"}
                                w={"50%"}
                                h={"30px"}
                                borderRadius='lg'
                                color='white'
                                // flexDir={"column"}
                            >
                                Account:
                                <Text fontWeight={"bold"}>{`${account.substring(0, 6)}...${account.substring(
                                    account.length - 4
                                )}`}</Text>
                            </Flex>
                        </Center>
                    ) : (
                        <ConnectButton/>
                    )
                }
                {active && account && !errMsg && <MintBox/>}
                <Spacer h={20}/>
                <WalletConnectError/>
            </Box>
            <Box>
                <Marquee list={DATA_LIST} time={8}/>
            </Box>
        </Flex>
    )
}


export default MainMint;
