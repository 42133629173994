import {keyframes} from "@chakra-ui/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";

// Wrapper
const WrapperContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ccc;
`;

// Marquee
const moveLeft = keyframes`
  from {
    transform: translateX(0);
  }
`;

const MarqueeContainer = styled.div`
  position: relative;
  width: 100vw;
  //margin-top: 20px;
  //padding: 10px 0;
  background-color: white;
  overflow: hidden;
  border-top: 5px solid #ff1a88;
  border-bottom: 5px solid #ffea41;

  //&:hover {
  //  animation-play-state: paused;
  //}

  //&::after {
  //  position: absolute;
  //  content: "";
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  pointer-events: none;
  //  background-image: linear-gradient(to right,
  //  #ccc,
  //  transparent 20%,
  //  transparent 80%,
  //  #ccc);
  //}
`;

const MarqueeArea = styled.div`
  display: inline-block;
  white-space: nowrap;
  transform: translateX(-${(props) => props.move}px);
  animation: ${moveLeft} ${(props) => props.time}s linear infinite ${(props) => (props.toRight ? " reverse" : "")};
  animation-play-state: inherit;
`;

const MarqueeItem = styled.div`
  position: relative;
  padding-top: 5px;
  display: inline-block;
  margin-right: 2em;
  color: black;
  font-size: 40px;
  font-family: Chunky Rosie,sans-serif;
`;

const getFillList = (list, copyTimes = 1) => {
    let newlist = [];
    for (let i = 0; i < copyTimes; i++) {
        newlist.push(...list);
    }
    return newlist;
};

const Marquee = ({list, time, toRight, ...props}) => {
    const marqueeContainer = useRef(null);
    const marqueeArea = useRef(null);
    const [moveLeft, setMoveLeft] = useState(0);
    const [showList, setShowList] = useState(list);
    const [realTime, setRealTime] = useState(time);

    useEffect(() => {
        const containerWidth = Math.floor(marqueeContainer.current.offsetWidth);
        const areaWidth = Math.floor(marqueeArea.current.scrollWidth);
        // 複製次數最小為2(跑馬燈寬度的兩倍以上)
        const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));
        // 單圈移動距離
        const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);
        // 一圈實際時間
        const newRealTime =
            time * parseFloat((newMoveLeft / containerWidth).toFixed(2));
        setShowList(getFillList(list, copyTimes));
        setMoveLeft(newMoveLeft);
        setRealTime(newRealTime);

        // console.log(
        //     "containerWidth",
        //     containerWidth,
        //     "areaWidth",
        //     areaWidth,
        //     "copyTimes",
        //     copyTimes,
        //     "newRealTime",
        //     newRealTime
        // );
    }, [list]);

    return (
        <MarqueeContainer ref={marqueeContainer} {...props}>
            <MarqueeArea
                ref={marqueeArea}
                move={moveLeft}
                time={realTime}
                toRight={toRight}
            >
                {showList.map((item) => {
                    return <MarqueeItem>{item}</MarqueeItem>;
                })}
            </MarqueeArea>
        </MarqueeContainer>
    );
};

Marquee.propTypes = {
    list: PropTypes.array,
    time: PropTypes.number,
    toRight: PropTypes.bool
};

Marquee.defaultProps = {
    list: [],
    time: 10
};


export default Marquee;
