import React from 'react';
import ReactDOM from 'react-dom/client';
import {Web3ReactProvider} from '@web3-react/core';

import './index.css';
import App from './App';
import {Web3Provider} from "@ethersproject/providers";
import {POLLING_INTERVAL} from "./dapp/connectors";
import {ContractProvider} from "./context/Contract";

const root = ReactDOM.createRoot(document.getElementById('root'));

function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = POLLING_INTERVAL;
    return library;
}

root.render(
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <ContractProvider>
                <App/>
            </ContractProvider>
        </Web3ReactProvider>
    </React.StrictMode>
);
