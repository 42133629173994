import wildWarZShowcaseNFT from "../WildWarZWhitelist.json";
import React, {useEffect, useState} from "react";
import {ethers} from "ethers";
import {Box, Button, Center, Flex, Link, Text} from "@chakra-ui/react";
import {useWeb3React} from "@web3-react/core";
import logger from "../logger";
import {useContractContext} from "../context/Contract";
import {FaWallet} from "react-icons/fa";

const wildWarZShowcaseNFTAddress = process.env.REACT_APP_WHITELIST_CONTRACT_ADDRESS;
const publicNetworkId = process.env.REACT_PUBLIC_NETWORK_ID || '137';
const openSeaLink = process.env.REACT_APP_SHOWCASE_NFT_COLLECTION_OPENSEA_LINK;

const MintBox = ({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [userMessage, setUserMessage] = useState("");

    const {
        connector,
        library,
        account,
        activate,
        deactivate,
        active,
        error,
        setError,
        chainId
    } = useWeb3React();
    const {errMsg, setErrMsg} = useContractContext();

    const [isChecking, setIsChecking] = useState(true);
    const [hasFreeMint, setHasFreeMint] = useState(false);
    const [checkError, setCheckError] = useState("");
    const [txnError, setTxnError] = useState("");
    const [isMinting, setIsMinting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isMintSuccess, setIsMintSuccess] = useState(false);


    const checkIfWhitelisted = async () => {
        // setIsMintSuccess(true);
        // setUserMessage("Minting was successful!");
        logger.info("checkIfWhitelisted");
        if (active && account) {
            const provider = library || new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(
                wildWarZShowcaseNFTAddress,
                wildWarZShowcaseNFT.abi,
                provider
            );
            console.log("account", account);
            console.log("wildWarZShowcaseNFTAddress", wildWarZShowcaseNFTAddress);
            let isWhitelisted = false;
            let isFreeMintListed = false;
            try {
                // Check whether the user is whitelisted
                isWhitelisted = await contract.isWhitelisted(account);
                console.log("checkIfWhitelisted -> isWhitelisted", isWhitelisted);
                // Check whether the user is free mint listed
                isFreeMintListed = await contract.isFreeMintListed(account);
                console.log("checkIfWhitelisted -> isFreeMintListed", isFreeMintListed);

                // let whitelistBalance = await contract.balanceOf(account, 0);
                // whitelistBalance = whitelistBalance.toNumber();
                // console.log("whitelistBalance -> whitelistBalance", whitelistBalance);
                //
                // let freeMintBalance = await contract.balanceOf(account, 1);
                // freeMintBalance = freeMintBalance.toNumber();
                // console.log("freeMintBalance -> freeMintBalance", freeMintBalance);

                setIsChecking(false);
                setCheckError("");

                if (isFreeMintListed) {
                    setHasFreeMint(true);
                } else if (isWhitelisted) {
                    setHasFreeMint(false);
                } else {
                    setCheckError("Sorry! You do not have any whitelist");
                }
            } catch (e) {
                console.log("checkIfWhitelisted -> error", e);
                setIsChecking(false);
                setCheckError("Something went wrong while connecting to your Metamask wallet! Please try again");
            }
        }
    };

    useEffect(() => {
        console.log("chainId", chainId);
        console.log("publicNetworkId", publicNetworkId);

        setIsChecking(true);
        setCheckError("");
        setTxnError("");
        setUserMessage("");
        setIsMinting(false);
        setIsDisabled(false);

        if (
            active && account &&
            chainId && chainId.toString() === publicNetworkId
        ) {
            checkIfWhitelisted();
        }
    }, [activate, account, chainId]);

    async function handleMint() {
        setUserMessage("");
        setIsMinting(true);
        setTxnError("");
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                wildWarZShowcaseNFTAddress,
                wildWarZShowcaseNFT.abi,
                signer
            );

            try {
                let response;
                if (hasFreeMint) {
                    response = await contract.freeMint(account);
                } else {
                    response = await contract.whitelistMint(account);
                }
                console.log("response:", response);
                await response.wait();
                setIsMintSuccess(true);
                setUserMessage("Minting was successful!");
                setIsDisabled(true);
                setIsMinting(false);
            } catch (e) {
                let message = "Something went wrong";
                if (e && e.data && e.data.message) {
                    message = e.data.message;
                } else if (e && e.error && e.error.message) {
                    message = e.error.message;
                } else if (e && e.message) {
                    message = e.message;
                } else if (e && e.data && e.data.originalError && e.data.originalError.message) {
                    message = e.data.originalError.message;
                }

                const loweredCaseMessage = message.toLowerCase();

                if (loweredCaseMessage.includes("e001")) {
                    message = "Minting is not enabled!"
                } else if (loweredCaseMessage.includes("e002")) {
                    message = "Sorry! You are not whitelisted."
                } else if (loweredCaseMessage.includes("e003")) {
                    message = "Sorry! You are not free mint listed."
                } else if (loweredCaseMessage.includes("e004")) {
                    setIsMintSuccess(true);
                    setIsDisabled(true);
                    setUserMessage("You have already minted!");
                    // message = "You have already minted!"
                    message = undefined;
                }

                if (message) {
                    setTxnError(message);
                }
                setIsMinting(false);
                setIsDisabled(false);
            }
        } else {
            setTxnError("Something went wrong while connecting to your Metamask wallet! Please try again");
        }
    }

    return (
        <Flex
            mt={"30px"}
            justify={"center"}
            flexDir={"column"}
            align={"center"}
        >
            {
                isChecking &&
                <Box
                    color={"white"}
                >
                    <Text>Checking...</Text>
                </Box>
            }
            {
                checkError &&
                <Box
                    color={"red"}
                >
                    <Text>{checkError}</Text>
                </Box>
            }
            {
                txnError &&
                <Box
                    color={"red"}
                >
                    <Text>{txnError}</Text>
                </Box>
            }
            {
                userMessage &&
                <Box
                    color={"green"}
                    backgroundColor={"white"}
                    mb={"10px"}
                    borderRadius={"5px"}
                    px={"10px"}
                    py={"5px"}
                >
                    <Text>{userMessage}</Text>
                    {isMintSuccess && <Link target={"_blank"} href={openSeaLink} blank>
                        Check here
                    </Link>}
                </Box>
            }
            {
                !isChecking &&
                !checkError &&
                <Button
                    backgroundColor={"#ec3f3f"}
                    borderRadius={"5px"}
                    boxShadow={"0px 2px 2px 1px #0F0F0F"}
                    color={"white"}
                    cursor={"pointer"}
                    fontFamily={"inherit"}
                    padding={"15px"}
                    margin={"0 15px"}
                    onClick={handleMint}
                    isLoading={isMinting}
                    isDisabled={isDisabled}
                    h={"50px"}
                    leftIcon={<FaWallet/>}
                >
                    <Center>
                        {hasFreeMint ? "Get Free Mint PASS" : "Get Whitelist PASS"}
                    </Center>
                </Button>
            }
        </Flex>
    )
}


export default MintBox;
