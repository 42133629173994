import {useWeb3React} from '@web3-react/core';
import {FaWallet} from 'react-icons/fa';

import {useContractContext} from "../../context/Contract";
import {injected} from "../../dapp/connectors";
import {Button, Center, Text} from "@chakra-ui/react";

const polygonMainNetPublicNetworkId = process.env.REACT_PUBLIC_NETWORK_ID;

export default function ConnectButton() {
    const {activate, setError, chainId} = useWeb3React();

    const {isConnecting, setErrMsg, setIsConnecting} = useContractContext();

    async function connectMetaMask() {
        if (typeof window.ethereum !== 'undefined') {
            setIsConnecting(true);
            try {
                await activate(injected);
                setIsConnecting(false);
                if (
                    chainId &&
                    chainId.toString() !== polygonMainNetPublicNetworkId
                ) {
                    setErrMsg(
                        `Change the network to ${polygonMainNetPublicNetworkId}.`
                    );
                }
            } catch (error) {
                if (error instanceof Error) setError(error);
                setIsConnecting(false);
            }
        } else {
            setErrMsg('Please install MetaMask.');
        }
    }

    return (
        <div className="flex justify-center">
            <Button
                backgroundColor={"#ec3f3f"}
                borderRadius={"5px"}
                boxShadow={"0px 2px 2px 1px #0F0F0F"}
                color={"white"}
                cursor={"pointer"}
                fontFamily={"inherit"}
                padding={"15px"}
                margin={"0 15px"}
                onClick={connectMetaMask}
                isLoading={isConnecting}
                h={"50px"}
                leftIcon={<FaWallet/>}
                >
                <Center>
                    <Text>{isConnecting ? "Connecting" : "Connect"}</Text>
                </Center>
            </Button>
            {/*<Button*/}
            {/*    onClick={connectMetaMask}*/}
            {/*    isLoading={isConnecting}*/}
            {/*    maxW={'md'}*/}
            {/*    colorScheme={'orange'}*/}
            {/*    leftIcon={<FaWallet/>}>*/}
            {/*    <Center>*/}
            {/*        <Text>{isConnecting ? "Connecting" : "Connect"}</Text>*/}
            {/*    </Center>*/}
            {/*</Button>*/}
        </div>
    );
}
