import {createContext, useContext, useState,} from 'react';


const ContractContext = createContext({});

export function ContractProvider({children}) {
    const [message, setMessage] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isConnecting, setIsConnecting] = useState(false);

    return (
        <ContractContext.Provider
            value={{
                message,
                errMsg,
                isConnecting,
                setMessage,
                setErrMsg,
                setIsConnecting,
            }}
        >
            {children}
        </ContractContext.Provider>
    );
}

export function useContractContext() {
    return useContext(ContractContext);
}
