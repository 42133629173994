import loglevel from "loglevel";

export default {
    warn: loglevel.warn,
    debug: loglevel.debug,
    info: loglevel.info,
    error: loglevel.error,
    trace: loglevel.trace,
    setLevel: loglevel.setLevel,
};
